@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');
@import "./styles/variables/colors";
@import "./styles/main.scss";

body {
  margin: 0;
  //font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::selection{
    background-color: $red-default;
    color:$white-default
  }
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Poppins', sans-serif;
}

p, a, span, label, button{
  font-family: 'Poppins', sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: $system;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(32, 32, 32); 
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #970909; 
// }
