@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');

.title-logo-top, .title-logo-bottom{
    font-family: 'Questrial', sans-serif;
    font-size: 40px;
    color:$white-default;
    -webkit-user-select: none;

    &.dark-mode{
        color:$white-default;
    }
    &.light-mode{
        color:$system;
    }
}

.title-logo-bottom{
    margin-top: -30px;
    span{
        font-family: 'Questrial', sans-serif;
        color:$red-default;
        font-size: 50px;
    }
}

// .blob { 
//     width: 1000px;
//     height: 1000px;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3Cfilter id=%22grain%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E %3CfeFlood flood-color=%22%23ffffff%22 result=%22neutral-gray%22 %2F%3E %3CfeTurbulence in=%22neutral-gray%22 type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22 %2F%3E %3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22 %3E%3C%2FfeColorMatrix%3E %3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E %3CfeFuncA type=%22table%22 tableValues=%220 0 0.1 0%22%3E%3C%2FfeFuncA%3E %3C%2FfeComponentTransfer%3E %3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22 %2F%3E %3C%2Ffilter%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M778%2C677.5Q615%2C855%2C427.5%2C772Q240%2C689%2C211.5%2C479.5Q183%2C270%2C394%2C223Q605%2C176%2C773%2C338Q941%2C500%2C778%2C677.5Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg filter=%22url(%23grain)%22 clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22%23de3535%22 d=%22M778%2C677.5Q615%2C855%2C427.5%2C772Q240%2C689%2C211.5%2C479.5Q183%2C270%2C394%2C223Q605%2C176%2C773%2C338Q941%2C500%2C778%2C677.5Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
//   }
  