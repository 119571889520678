
.button{
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15PX;
    border: none;

    
    &.default{        
        background-color: $red-default;
        color: white;
    }

}