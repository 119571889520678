.title-component{
    margin: 0;
    height: fit-content;
    &.h2{
        font-size: 36px;
    }
    &.h3{
        font-size: 28px;
        
    }
    &.h4{
        font-size: 22px;
        
    }
    &.h5{
        font-size: 18px;
        
    }
    &.h6{
        font-size: 16px;

    }
}