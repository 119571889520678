
.blob-image{
    position: relative;
    &_blob{
        width: 350px;
        height: 350px;
        background: linear-gradient(180deg, #F14040 0%, rgba(242, 45, 45, 0.49) 100%);
        animation: animate-blob 5s ease-in-out infinite;
        transition: all 1s ease-in-out;
    }
    &_image{
        top: 5%;
        position: absolute;
        width: 350px;
        animation: animate-image 5s ease-in-out infinite;
        transition: all 1s ease-in-out;
    }
}

@keyframes animate-image {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}
@keyframes animate-blob {
    0%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    50%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    100%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
        // border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}