.about-me-contente{
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.texts, &.images{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        
        .about-me-title{
            margin: 5px 0 -7px 0;
        }
        
        .about-me-subtitle{
            color: $red-default;
            margin-bottom: 15px ;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
        }

        .about-me-text{
            padding: 0 70px 0 0;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 15px;
        }
    }
    
    &.texts{
        width: 50%;
    }
    
    &.images{
        width: 40%;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .dark-mode{
        &.texts{
            .about-me-text, .about-me-title{
                color: white;
            }
        }
        
    } 
    
    .light-mode{
        &.texts{
            .about-me-text, .about-me-title{
                color: #333;
            }
            
        }
        
        
    }


}