@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap');

.socials-container {
    display: flex;
    flex-direction:column ;
    justify-content: center;
    text-align: center;
    align-items: center;

    .btn-socials {
        margin: 0 16px;
        margin-top: 30px;
        border-radius: 5px;
        background-color: transparent;
        transition: .3s linear ;
        border: none;

        &.dark-mode{
            svg{
                color:$white-default;
            }
        }
        
        &.light-mode{
            svg{
                color:$system;
            }
        }

        &:hover{
            transform: scale(1.5);

            svg{
                color: $red-default;
            }
        }


    }
}

@media screen and (max-width: 390px) {
    .socials-container {
        .btn-socials {
            margin: 0 8px;
            margin-top: 30px;
        }
    }
}